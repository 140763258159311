<template>
  <div
    v-if="showAlert"
    class="ccc-notice-box"
  >
    <s-alert
      v-if="showAlert"
      class="ccc-notice-alert"
      :type="type"
      :closeable="closable"
      :show-icon="showIcon"
      :rtl-icon-roate="rtlIconRoate"
      :max-rows="3"
      :description="message"
      :load-more-txt="loadingText"
      @on-load-more-click="onLoadMoreClick"
      @close="closeAlertEvt"
    />
    <SLazyMount>
      <s-dialog
        v-model:visible="showText"
        type="W480"
        :show-close="false"
        :close-on-click-modal="true"
        :immediately-render="true"
        :append-to-body="false"
        class="ccc-notice-dialog"
      >
        <template #title></template>
        <div
          class="notice-ctn"
          v-html="message"
        >
        </div>
        <template #footer>
          <s-button-group>
            <s-button-group-item
              :type="['primary']"
              @click="showText = !showText"
            >
              {{ language.SHEIN_KEY_PC_15216 }}
            </s-button-group-item>
          </s-button-group>
        </template>
      </s-dialog>
    </SLazyMount>
  </div>
</template>

<script>
import { htmlDecode } from '@shein/common-function'
import { SLazyMount } from '@shein-aidc/sui-lazy-mount'
const { language = {} } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}
export default {
  name: 'CommonNotice',
  components: {
    SLazyMount
  },
  props: {
    message: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'info',
    },
    closable: {
      type: Boolean,
      default: true,
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
    rtlIconRoate: {
      type: Boolean,
      default: true,
    },
    loadingText: {
      type: String,
      default: language.SHEIN_KEY_PC_15731,
    },
  },
  data() {
    return {
      language,
      showAlert: false,
      showText: false,
    }
  },
  computed: {
    key() {
      let timer = new Date().getTime()
      return `${timer}_notice`
    },
  },
  watch: {
    message(val) {
      this.showAlert = false
      this.$nextTick(() => {
        this.showAlert = !!val
      })
    }
  },
  created() {
    this.showAlert = !!this.message
  },
  methods: {
    htmlDecode: htmlDecode,
    onLoadMoreClick() {
      this.showText = !this.showText
      this.$emit('showDetailEvt')
    },
    closeAlertEvt() {
      this.showAlert = false
      this.$emit('closeAlert')
    }
  },
  emits: ['showDetailEvt', 'closeAlert'],
}
</script>
<style lang="less" scoped>
.ccc-notice-box {
  .ccc-notice-alert {
    padding: 10px;
    .txt-l();
  }
  .ccc-notice-dialog {
    .notice-ctn {
      max-height: 430px;
      overflow-y: auto;
      padding: 10px 5px 10px 5px;
      word-break: break-word;
    }
  }
}
</style>
