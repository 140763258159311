<template>
  <div class="user-assets__wrapper">
    <div class="user-assets__eyes">
      <Icon
        v-if="!isRisk"
        is-responsive-name
        class="user-assets__eyes-icon"
        :is-rotate="constantData.GB_cssRight"
        :name="isShow ? 'sui_icon_eyes_open_16px' : 'sui_icon_eyes_closed_16px'"
        @click="toggleShowAssets"
      />
    </div>
    <ul class="user-assets__content">
      <li
        v-for="item in list"
        :key="item.id"
        v-enterkey
        class="user-assets__item"
        role="link"
        tabindex="0"
        :title="item.title"
        :aria-label="item.title"
        @click="handleItemClick(item.id, item.link)"
      >
        <component
          :is="iconMap[item.extra]"
          v-if="item.showIcon"
          size="32px"
          height="36px"
        />
        <span
          v-else
          :class="{ 'user-assets__item-extra': true, 'is-show': isShow }"
        >
          <span
            v-if="item.extraHtml"
            v-html="item.extraHtml"
          ></span>
          <template v-else>
            {{ item.extra }}
          </template>
        </span>
        <span class="user-assets__item-text">{{ item.title }}</span>
      </li>
    </ul>
    <UserAssetsBottom
      :user-info="userInfo"
      :expire-point-coupon-info="expirePointCouponInfo"
      :on-click="handleItemClick"
      :on-close="handleItemClick"
    />
    <VipModules
      :is-prime="isPrime"
      :language="language"
      :is-show-saver="isShowSaver"
      :data="personalCenterEntranceInfo"
      :apollo-config="apolloConfig"
    />
  </div>
</template>
<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import { Icon, sui_icon_me_coupon_32px, sui_icon_me_points_32px, sui_icon_me_wallet_32px, sui_icon_me_gift_32px } from '@shein-aidc/icon-vue3'
import UserAssetsBottom from './user-assets-bottom/UserAssetsBottom.vue'
import VipModules from './VipModules.vue'
import { setLocalStorage } from '@shein/common-function'
import { getExpireCacheKey } from './user-assets-bottom/utils'
import { analysisEvent } from '../../analysis'
import { useCommonState } from '../../utils/useCommonState'
import { daEventCenter } from 'public/src/services/eventCenter'

defineProps({
  expirePointCouponInfo: {
    type: Object,
    default: () => ({})
  },
  personalCenterEntranceInfo: {
    type: Object,
    default: () => ({})
  },
  isShowSaver: {
    type: Boolean,
    default: false
  },
  isPrime: {
    type: Boolean,
    default: false
  }
})
const { userInfo, language, constantData, isRisk, apolloConfig } =
  useCommonState()
const isShow = ref(false)

const iconMap = {
  sui_icon_me_coupon_32px,
  sui_icon_me_points_32px,
  sui_icon_me_wallet_32px,
  sui_icon_me_gift_32px,
}

const list = computed(() => {
  const { coupons = 0, point = 0, wallet_amount = '', wallet_currency } = userInfo.value
  return [
    {
      id: 'coupon',
      title: language.value.SHEIN_KEY_PC_14930,
      extra: isShow.value ? +coupons || 'sui_icon_me_coupon_32px' : '***',
      link: '/user/coupon',
      showIcon: isShow.value && !+coupons
    },
    {
      id: 'points',
      title: language.value.SHEIN_KEY_PC_15043,
      extra: isShow.value ? +point || 'sui_icon_me_points_32px' : '***',
      link: '/user/points',
      showIcon: isShow.value && !+point
    },
    {
      id: 'wallet',
      title: language.value.SHEIN_KEY_PC_15391,
      extra: isShow.value ? 'sui_icon_me_wallet_32px' : '***',
      extraHtml: isShow.value ? `${wallet_amount}<sub style="bottom:0;">${wallet_currency}</sub>` : '***',
      link: '/user/wallet',
      showIcon: isShow.value && (Number(wallet_amount) === 0)
    },
    {
      id: 'giftCard',
      title: language.value.SHEIN_KEY_PC_15570,
      extra: 'sui_icon_me_gift_32px',
      link: '/user/gift_card',
      showIcon: true
    }
  ]
})
const getAssetsState = () => {
  const state = localStorage.getItem('IS_SHOW_ASSETS')
  isShow.value = !isRisk.value && state === '1'
  isRisk.value && localStorage.setItem('IS_SHOW_ASSETS', '0')
}
const toggleShowAssets = () => {
  isShow.value = !isShow.value
  localStorage.setItem('IS_SHOW_ASSETS', isShow.value ? '1' : '0')
}
const handleItemClick = (type, url) => {
  const activityParams = {}
  if (type === 'giftCard') {
    daEventCenter.triggerNotice({
      daId: '1-22-2-1010',
      extraData: {}
    })
  } else if (isRisk.value) {
    return window.userIndexEventCenter.emit('riskDialog', true)
  }
  if (type === 'coupon' || type === 'points') {
    setLocalStorage({
      key: getExpireCacheKey(type, userInfo.value),
      value: 1,
      expire: 3600 * 24 * 3 * 1000
    })
    daEventCenter.triggerNotice({
      daId: type === 'coupon' ? '1-22-2-11' : '1-22-2-13',
      extraData: {
        reminder_type: 1,
        operation: 1
      }
    })
    if (type === 'coupon') {
      activityParams.coupon_num = userInfo.value.coupons || 0
    } else {
      activityParams.points_num = userInfo.value.point || 0
    }
  }
  if (type === 'wallet') {
    activityParams.wallet_num = userInfo.value.wallet_amount || 0
  }
  analysisEvent(type, activityParams)
  setTimeout(() => {
    window.location.href = url
  }, 300)
}

onMounted(() => {
  getAssetsState()

  watch(userInfo, val => {
    if (!val) return
    daEventCenter.triggerNotice({
      daId: '1-22-2-33',
      extraData: {
        my_assets_list: [
          { item_type: 'my_coupons', coupon_num: val.coupons || 0 },
          { item_type: 'shein_points', points_num: val.point || 0 },
          { item_type: 'my_wallet', wallet_num: val.wallet_amount || 0 },
          { item_type: 'gift_card' }
        ]
      }
    })
  }, { immediate: true })
})
</script>
<style lang="less" scoped>
.user-assets {
  // padding: 4px 30px 44px;

  &__eyes {
    display: flex;
    justify-content: flex-end;
  }

  &__eyes-icon {
    margin-top: 10px;
    cursor: pointer;
  }

  &__content {
    display: flex;
    gap: 8px;
    margin-top: 22px;
  }

  &__item {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;

    &:hover {
      .user-assets__item-text {
        color: #222;
      }
      // stylelint-disable-next-line
      .user-assets__item-extra.is-show {
        transform: scale(1.2);
      }
    }
  }

  &__item-extra {
    height: 36px;
    line-height: 36px;
    font-size: 16px;
    font-weight: bold;
    & when (@IS_RW) {
      font-family: Adieu;
    }
  }

  &__item-text {
    margin-top: 10px;
    color: #666;
    font-size: 14px;
    text-align: center;
  }
}
</style>
