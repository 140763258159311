export const EXPIRE_GAME_MONEY_CACHE_KEY = 'personal-center__expire-game-money'

export const EXPIRE_COUPON_CACHE_KEY = 'personal-center__expire-coupon'

export const EXPIRE_POINTS_CACHE_KEY = 'personal-center__expire-points'

export const EXPIRE_KEY_MAP = {
  gameMoney: EXPIRE_GAME_MONEY_CACHE_KEY,
  coupon: EXPIRE_COUPON_CACHE_KEY,
  points: EXPIRE_POINTS_CACHE_KEY
}

export const ExpireTipsClickStatus = {
  // 不再展示
  NO_LONGER_SHOW: 1,
  // 可关闭
  CAN_BE_CLOSED: 2,
}
