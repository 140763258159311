<template>
  <div class="my-order__wrapper">
    <div class="my-order__header">
      <span class="my-order__header-title">
        {{ language.SHEIN_KEY_PC_14895 }}
      </span>
      <div
        v-enterkey
        class="my-order__header-more"
        :aria-label="language.SHEIN_KEY_PC_14663"
        @click="toLink('/user/orders/list', 'allOrder')"
      >
        {{ language.SHEIN_KEY_PC_14663 }}
        <sui_icon_more_right_12px
          size="12px"
          class="my-order__header-more-icon"
          :is-rotate="constantData.GB_cssRight"
        />
      </div>
    </div>
    <ul class="my-order__tab">
      <li
        v-for="item in orderTab"
        :key="item.id"
        v-enterkey
        class="my-order__tab-item"
        :aria-label="item.title"
        @click="item.onClick?.(item) || toLink(item.link, item.id)"
      >
        <span class="my-order__tab-item-icon">
          <component
            :is="iconMap[item.id]"
            size="32px"
          />
          <span
            v-if="item.num && !isRisk"
            class="my-order__tab-item-dot"
          >
            <em>{{ formatNum(item.num) }}</em>
          </span>
        </span>
        <span class="my-order__tab-item-text">{{ item.title }}</span>
      </li>
    </ul>
    <!-- 待评论订单提示 -->
    <UnReviewList
      :language="language"
      :un-review-list="unReviewList"
    />
    <!-- 订单列表 -->
    <OrderList
      :order-list="orderList"
      :show-preferential-floor="showPreferentialFloor"
    />
  </div>
</template>

<script setup>
import { computed, inject, ref, watch, onMounted } from 'vue'
import { sui_icon_more_right_12px, sui_icon_me_unpaid_32px, sui_icon_me_processing_32px, sui_icon_me_shipped_32px, sui_icon_me_returns_32px, sui_icon_me_review_32px } from '@shein-aidc/icon-vue3'
import UnReviewList from './UnReviewList.vue'
import OrderList from './OrderList.vue'
import { useCommonState } from '../../utils/useCommonState'
import { daEventCenter } from 'public/src/services/eventCenter'

const props = defineProps({
  dynamicService: {
    type: Object,
    default: () => ({})
  }
})

const { getUserData } = inject('USER_CENTER_CONTEXT')
const toLink = inject('toLink', () => {})
const { userInfo, userInfoReady, language, constantData, abtInfo, isRisk } =
  useCommonState()

const iconMap = {
  unpaid: sui_icon_me_unpaid_32px,
  processing: sui_icon_me_processing_32px,
  shipped: sui_icon_me_shipped_32px,
  returns: sui_icon_me_returns_32px,
  review: sui_icon_me_review_32px,
}

const orderTab = computed(() => {
  const data = [
    {
      id: 'unpaid',
      title: language.value.SHEIN_KEY_PC_15233,
      num: userInfo.value.waiting_payment || 0,
      link: '/user/orders/list?status_type=1'
    },
    {
      id: 'processing',
      title: language.value.SHEIN_KEY_PC_15031,
      num: userInfo.value.processing_order || 0,
      link: '/user/orders/list?status_type=2'
    },
    {
      id: 'shipped',
      title: language.value.SHEIN_KEY_PC_15026,
      num: userInfo.value.waiting_receive || 0,
      link: '/user/orders/list?status_type=3'
    },
    {
      id: 'returns',
      title: language.value.SHEIN_KEY_PC_15773,
      num: userInfo.value.after_sale || 0,
      link: '/user/orders/list?status_type=4'
    }
  ]
  // 是否增加评论中心入口 abt
  const isOpenReviewEntry = abtInfo.value.Reviewentry?.p === 'type=A'
  if (isOpenReviewEntry) {
    data.splice(3, 0, {
      id: 'review',
      title: language.value.SHEIN_KEY_PC_14850,
      num: userInfo.value.waiting_review || 0,
      link: '/user/review_center',
      onClick(item) {
        daEventCenter.triggerNotice({
          daId: '1-22-2-5',
          extraData: {
            extra_amount: item.num
          }
        })
        toLink(item.link, item.id)
      }
    })
  }
  return data
})
// 1-待支付, 2-待评论
const myOrders = computed(() => {
  const myOrder = props.dynamicService.serviceList?.find(
    item => item.type === 'myOrder'
  )
  return myOrder?.orderGroup?.orders || []
})
const unReviewList = computed(() =>
  myOrders.value.filter(item => item.orderType === 2)
)
const unPaidList = computed(() =>
  myOrders.value.filter(item => item.orderType === 1)
)
// 展示突出优惠力度楼层
const showPreferentialFloor = computed(
  () => unPaidList.value?.[0]?.abtValue == 'On'
)

const { userOrderList } = getUserData()
const allOrderList = ref(userOrderList?.info?.orders || [])

const orderList = computed(() => {
  if (!allOrderList.value && !unPaidList.value) return null
  const unPaidOrderList = unPaidList.value || []
  return allOrderList.value?.map((n, i) => ({
    ...n,
    ...unPaidOrderList[i]
  }))
})

const formatNum = num => (num > 99 ? '99+' : num || 0)

onMounted(() => {
  watch(userInfoReady, val => {
    if (!val) return
    const orderStatusNum = orderTab.value
      .map(n => [n.title, n.num].join('_'))
      .join(',')
    // 订单状态曝光埋点
    daEventCenter.triggerNotice({
      daId: '1-22-2-18',
      extraData: {
        orderstatus_num: orderStatusNum
      }
    })
  }, { immediate: true })
})
</script>
<style lang="less">
.my-order {
  &__wrapper {
    margin-top: 10px;
    padding: 10px 10px 20px;
    background: #fff;
    & when (@IS_RW) {
      border-radius: 4px;
    }
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 15px 10px;
  }
  &__header-title {
    text-transform: capitalize;
    font-size: 16px;
    font-weight: bold;
  }
  &__header-more {
    color: #666;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
    &:hover {
      color: #222;
    }
  }

  &__header-more-icon {
    margin-top: -2px;
    font-weight: normal;
  }

  &__tab {
    display: flex;
    align-items: center;
    padding: 20px 20px 0;
  }

  &__tab-item {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;

    &:hover {
      .my-order__tab-item-icon {
        transform: scale(1.2);
      }
      .my-order__tab-item-text {
        color: #222;
      }
    }
  }
  &__tab-item-icon {
    position: relative;
  }
  &__tab-item-dot {
    position: absolute;
    top: -6px;
    text-align: center;
    background: @sui_color_guide;
    color: #fff;
    font-size: 12px;
    height: 14px;
    line-height: 14px;
    border-radius: 8px;
    padding: 0 4px;
    background-clip: padding-box;
    vertical-align: middle;
  }
  &__tab-item-text {
    margin: 10px 0;
    color: #666;
    font-size: 14px;
    text-align: center;
  }
}
</style>
