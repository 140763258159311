<template>
  <div
    v-enterkey
    class="user-info__wrapper"
    @click="toLink('/user/account_setting', 'profile')"
  >
    <div class="user-info__content">
      <div class="user-info__nickname">
        <div class="user-info__nickname-text">
          {{ nickname }}
        </div>
        <img
          v-if="userInfo.is_student"
          class="user-info__student-icon"
          src="https://img.ltwebstatic.com/images3_ccc/2024/08/02/2c/1722570206b525e31cb87fc4d4fe2db0c52adef62c.png"
          alt="studentIcon"
        />
        <img
          v-if="userInfo.member_id"
          class="user-info__vip-icon"
          :src="userInfo.level_image?.url"
          @click.stop="toLink('/user/sheinvip', 'vip')"
        />
        <club-badge
          v-if="isPrime"
          class="user-info__vip-icon"
          :config="constantData"
          @click.stop="toLink('/user/prime?productsource=me', 'prime')"
        />
        <div
          v-if="isShowSaver"
          class="user-info__saver-icon"
        ></div>
      </div>
    </div>
    <div class="user-info__more">
      {{ language.SHEIN_KEY_PC_16195 }}
      <sui_icon_more_right_12px
        size="12px"
        class="user-info__more-icon"
        color="#666"
        :is-rotate="constantData.GB_cssRight"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, inject } from 'vue'
import ClubBadge from 'public/src/pages/components/clubBadge'
import { sui_icon_more_right_12px } from '@shein-aidc/icon-vue3'
import { useCommonState } from '../../utils/useCommonState'

defineProps({
  // 付费用户
  isPrime: {
    type: Boolean,
    default: false
  },
  isShowSaver: {
    type: Boolean,
    default: false
  }
})
const toLink = inject('toLink', () => {})
const { userInfo, language, constantData } = useCommonState()
const nickname = computed(
  () =>
    language.value.SHEIN_KEY_PC_15746 +
    ',' +
    (userInfo.value.nickname || userInfo.value.email || '')
)
</script>

<style lang="less">
.user-info {
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    max-width: 656px;
  }

  &__content {
    flex: 1;
    overflow: hidden;
  }

  &__nickname {
    display: flex;
    align-items: center;
    font-size: 18px;
    color: #222;
    font-weight: bold;
  }
  &__nickname-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__student-icon {
    width: 14px;
    margin-left: 14px;
    cursor: 'pointer';
  }
  &__vip-icon {
    height: 18px;
    margin-left: 8px;
    cursor: 'pointer';
  }
  &__saver-icon {
    width: 80px;
    height: 16px;
    background: url('/she_dist/images/extra-label-4234d856b8.png') no-repeat
      center;
    background-size: contain;
    margin-left: 8px;
  }

  &__more {
    color: #666666;
    font-weight: bold;
  }

  &__more-icon {
    margin-top: -2px;
  }
}
</style>
