<template>
  <!-- 仅存在超省卡时展示 -->
  <div
    v-if="!isShowPrime"
    class="extra-pannel"
    @click="jumpTo"
  >
    <div class="extra-pannel__left">
      <div class="extra-pannel__logo-wrap">
        <img
          class="logo"
          :src="saveInfo.cardIcon"
        />
        <div
          v-if="expiredFlag"
          class="status"
        >
          {{ language.SHEIN_KEY_PC_24495 }}
        </div>
      </div>

      <div class="extra-pannel__content">
        <span v-html="content[0]"></span>
      </div>
    </div>

    <div
      class="extra-pannel__right"
      :class="{ 'extra-pannel__right-ar': GB_cssRight }"
    >
      <div class="extra-pannel__right-content">
        <div class="extra-pannel__btn">
          <div
            v-if="saveInfo.bobbleTip"
            class="extra-pannel__btn_limited"
            v-html="saveInfo.bobbleTip"
          ></div>
          <span v-html="saveInfo.buttonTip"></span>
          <sui_icon_more_right_14px
            :is-rotate="GB_cssRight"
          />
        </div>
        <div
          class="extra-pannel__coupon"
          v-html="content[1] || ''"
        ></div>
      </div>
    </div>
  </div>

  <!-- 与付费会员并存时展示 -->
  <div
    v-if="isShowPrime"
    class="extra-pannel2"
    :class="{ 'extra-pannel2__ar': GB_cssRight }"
    @click="jumpTo"
  >
    <div class="extra-pannel2__logo-wrap">
      <img
        class="logo"
        :src="extraLogo"
      />
      <div
        v-if="expiredFlag"
        class="status"
      >
        {{ language.SHEIN_KEY_PC_24495 }}
      </div>
    </div>
    <div class="extra-pannel2__content">
      <span v-html="content[0]"></span>
    </div>

    <div class="extra-pannel2__footer">
      <div
        v-if="!content[1]"
        class="extra-pannel2__btn-line"
      >
        <div
          v-if="saveInfo.bobbleTip"
          class="extra-pannel2__btn_limited"
          v-html="saveInfo.bobbleTip"
        ></div>
        <span v-html="saveInfo.buttonTip"></span>
        <sui_icon_more_right_14px
          :is-rotate="GB_cssRight"
        />
      </div>

      <template v-if="content[1]">
        <div
          class="extra-pannel2__tip"
          v-html="content[1] || ''"
        ></div>
        <div class="extra-pannel2__btn">
          <div
            v-if="saveInfo.bobbleTip"
            class="extra-pannel2__btn_limited"
            v-html="saveInfo.bobbleTip"
          ></div>
          <span v-html="saveInfo.buttonTip"></span>
          <sui_icon_more_right_14px
            :is-rotate="GB_cssRight"
          />
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { sui_icon_more_right_14px } from '@shein-aidc/icon-vue3'
import { daEventCenter } from 'public/src/services/eventCenter/index'

export default {
  name: 'ExtraPannel',
  components: {
    sui_icon_more_right_14px,
  },
  inject: ['commonState'],
  props: {
    language: {
      type: Object,
      default: () => ({})
    },
    isShowPrime: {
      type: Boolean,
      default: false
    },
    saveInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data() {

    const { langPath, GB_cssRight } = this.commonState.constantData

    return {
      langPath,
      GB_cssRight,
      extraLogo:
        '//img.ltwebstatic.com/images3_ccc/2024/05/30/6d/1717050373e7b069ad7584978fe1376df79f951475.webp'
    }
  },
  computed: {
    expiredFlag() {
      return this.saveInfo.status == 2 // 会员状态:1-临期，2-过期
    },
    content() {
      const data = this.saveInfo.richText || ''
      const [contentText, couponText] = data.split('@@')
      return [contentText, couponText]
    },
    buriedObject() {
      return this.saveInfo.buriedObject || []
    }
  },
  watch: {
    buriedObject: {
      handler(val) {
        if (!val?.length) return
        const saverReach = val.find(n => n.buriedKey === 'saver_reach')?.defaultParams
        daEventCenter.triggerNotice({
          daId: '1-22-2-1000',
          extraData: saverReach
        })
        const saverExpose = val.find(n => n.buriedKey === 'saver' && n.buriedType === 1)?.defaultParams
        daEventCenter.triggerNotice({
          daId: '1-22-2-1001',
          extraData: {
            activity_from: 'me',
            ...saverExpose
          }
        })
      },
      immediate: true
    }
  },
  methods: {
    jumpTo() {
      const saverClick = this.buriedObject.find(n => n.buriedKey === 'saver' && n.buriedType === 2)?.defaultParams
      daEventCenter.triggerNotice({
        daId: '1-22-2-1002',
        extraData: {
          activity_from: 'me',
          ...saverClick
        }
      })
      // let query = 'productsource=me'
      // location.href = `${langPath}/user/sheinsaver?${query}`
      if (this.saveInfo.appRoute) {
        location.href = this.langPath + this.saveInfo.appRoute
      } else {
        location.href = this.langPath + this.saveInfo.url
      }
    }
  }
}
</script>
<style lang="less" scoped>
.extra-pannel {
  width: 100%;
  margin-top: 28px;
  display: flex;
  min-height: 120px;
  border-radius: 6px;
  border: 1px solid rgba(255, 202, 214, 0.8);
  background: linear-gradient(270deg, #ffedf1 0%, #fcf2ff 100%);
  cursor: pointer;
  
  &__left {
    width: 350px;
    position: relative;
  }
  &__logo-wrap {
    display: flex;
    align-items: center;
    padding: 0 10px;
    height: 34px;
    width: fit-content;
    border-radius: 6px 0px 16px 0px;
    background: linear-gradient(
      89deg,
      rgba(255, 43, 119, 0.08) 25.9%,
      rgba(204, 49, 198, 0.08) 68.09%,
      rgba(252, 86, 255, 0.08) 100%
    );
    .logo {
      width: 130px;
    }
    .status {
      display: flex;
      padding: 2px 6px;
      margin-left: 6px;
      align-items: center;
      border-radius: 28px;
      border: 1px solid #b6bcdf;
      background: #ecf1fd;
      color: #5d6492;
    }
  }
  &__content {
    margin: 0 0 12px 12px;
    min-height: 74px;
    padding: 4px 0px;
    display: flex;
    align-items: center;
    color: #240b44;
    font-family: SF UI Display;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
  &__right {
    flex: 1;
    position: relative;
    padding: 16px 0;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 206px;
      height: 56px;
      background: url('/she_dist/images/extra-bg-0ba468084c.png') no-repeat;
      background-size: contain;
    }
  }
  &__right-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px 24px 0;
    border-left: 1px dashed rgba(248, 40, 84, 0.35);
    min-height: 88px;
  }
  &__right-ar::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 206px;
    height: 56px;
    background: url('/she_dist/images/extra-ar-bg-4f6a5c15fe.png') no-repeat;
    background-size: contain;
  }
  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 144px;
    min-height: 32px;
    gap: 2px;
    padding: 6px;
    color: #f82854;
    text-align: center;
    font-family: Arial;
    font-size: 13px;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    border-radius: 81px;
    border: 1px solid var(--saver-red, #f82854);
  }
  &__btn_limited {
    position: absolute;
    right: 0;
    top: -27px;
    display: inline-flex;
    padding: 2px 4px;
    justify-content: flex-end;
    align-items: center;
    color: #fff;
    text-align: right;
    font-family: SF UI Display;
    font-size: 12px;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    border-radius: 2px;
    background: linear-gradient(90deg, #f93776 0%, #d52bff 100%);
    &::after {
      content: '';
      position: absolute;
      bottom: -4px;
      right: 14px;
      width: 6px;
      height: 4px;
      background: url('/she_dist/images/extra-tip-80ad474b78.png') no-repeat;
      background-size: contain;
    }
  }
  &__coupon {
    margin-top: 8px;
    text-align: center;
    color: #240b44;
  }
}
.extra-pannel2 {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-left: 12px;
  width: 274px;
  min-height: 165px;
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid rgba(255, 202, 214, 0.8);
  background: linear-gradient(270deg, #ffedf1 0%, #fcf2ff 100%);
  cursor: pointer;
  &::after {
    content: '';
    position: absolute;
    top: 0px;
    right: 0;
    width: 72px;
    height: 56px;
    background: url('/she_dist/images/extra2-bg-d366509531.png') no-repeat;
    background-size: contain;
  }
  &__ar::after {
    content: '';
    position: absolute;
    top: 0px;
    right: 0;
    width: 72px;
    height: 56px;
    background: url('/she_dist/images/extra2-ar-bg-11399613bc.png') no-repeat;
    background-size: contain;
  }
  &__content {
    box-sizing: border-box;
    min-height: 64px;
    flex: 1;
    margin: 4px 12px;
    padding: 8px 0;
    display: flex;
    align-items: center;
    color: #240b44;
    font-family: SF UI Display;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  &__logo-wrap {
    display: inline-flex;
    height: 34px;
    padding: 0px 10px;
    align-items: center;
    flex-shrink: 0;
    width: fit-content;
    border-radius: 6px 0px 16px 0px;
    background: linear-gradient(
      89deg,
      rgba(255, 43, 119, 0.08) 25.9%,
      rgba(204, 49, 198, 0.08) 68.09%,
      rgba(252, 86, 255, 0.08) 100%
    );

    .logo {
      width: 98px;
    }
    .status {
      display: flex;
      padding: 2px 6px;
      margin-left: 6px;
      align-items: center;
      border-radius: 28px;
      border: 1px solid #b6bcdf;
      background: #ecf1fd;
      color: #5d6492;
    }
  }
  &__footer {
    padding: 4px 12px;
    min-height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgba(249, 55, 118, 0.08);
  }
  &__tip {
    color: #240b44;
    font-family: SF UI Display;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    flex: 1;
    margin-right: 4px;
  }
  &__btn {
    display: flex;
    position: relative;
    box-sizing: border-box;
    padding: 6px;
    justify-content: center;
    align-items: center;
    gap: 2px;
    border-radius: 80px;
    border: 1px solid #240b44;
    color: #240b44;
    text-align: center;
    font-family: Arial;
    font-size: 13px;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
  }
  &__btn-line {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 28px;
    padding: 6px;
    border-radius: 80px;
    border: 1px solid #240b44;
    color: #240b44;
    text-align: center;
    font-family: Arial;
    font-size: 13px;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
  }
  &__btn_limited {
    position: absolute;
    right: 0;
    top: -27px;
    width: max-content;
    display: inline-flex;
    padding: 2px 4px;
    justify-content: flex-end;
    align-items: center;
    color: #fff;
    font-family: SF UI Display;
    font-size: 12px;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    border-radius: 2px;
    background: linear-gradient(90deg, #f93776 0%, #d52bff 100%);
    &::after {
      content: '';
      position: absolute;
      bottom: -4px;
      right: 14px;
      width: 6px;
      height: 4px;
      background: url('/she_dist/images/extra-tip-80ad474b78.png') no-repeat;
      background-size: contain;
    }
  }
}
</style>
