<template>
  <div
    ref="container"
    class="container"
  >
    <span
      ref="scrollText"
      class="scroll-text"
    >{{ text }}</span>
  </div>
</template>

<script setup>
import { ref, nextTick, watchEffect } from 'vue'

// Props
defineProps({
  text: {
    type: String,
    default: ''
  },
})

const container = ref(null)
const scrollText = ref(null)

// 初始化和监听props.text的改变
watchEffect(() => {
  nextTick(() => {
    const containerWidth = container.value?.offsetWidth
    const textWidth = scrollText.value?.offsetWidth
    const difference = textWidth - containerWidth

    if (difference > 0) {
      const duration = difference * 200 // 初始设置或调整倍数因素
      const animationStyle = `
        @keyframes scrollAnimation {
          0%, 100% { transform: translateX(0); }
          50% { transform: translateX(-${difference}px); }
        }
      `

      applyAnimation(duration, animationStyle)
    }
  })
})

const applyAnimation = (duration, animationStyle) => {
  scrollText.value.style.animationName = 'scrollAnimation'
  scrollText.value.style.animationDuration = `${duration}ms`
  scrollText.value.style.animationTimingFunction = 'ease-in-out'
  scrollText.value.style.animationIterationCount = 'infinite'

  // 添加或更新动态创建的<style>元素
  let styleEl = document.head.querySelector('#dynamic-animation-style')
  if (!styleEl) {
    styleEl = document.createElement('style')
    styleEl.type = 'text/css'
    styleEl.id = 'dynamic-animation-style'
    document.head.appendChild(styleEl)
  }
  styleEl.innerHTML = animationStyle
}
</script>

<style lang="less" scoped>
.container {
  overflow: hidden;
  white-space: nowrap;
}

.scroll-text {
  display: inline-block;
}
</style>
