const mutations = {
  changeUnreadNum (state, unReadNum) {
    state.unReadNum = unReadNum < 0 ? 0 : unReadNum
  },
  changeActivityAndPromoOnly (state, isMsgActivityAndPromoOnly) {
    state.isMsgActivityAndPromoOnly = isMsgActivityAndPromoOnly
  },
  changeCouponOrPointsData (state, data) {
    state.couponOrPointsData = { ...state.couponOrPointsData, ...data }
  },
  toggleDialog (state, payload) {
    const { type = '', value = true, popContactBindType = 'change' } = payload

    switch (type) {
      case 'contact-bind': {
        state.popContactBind = !!value
        state.popContactBindType = popContactBindType
        break
      }
    }
  },
  setAreaCode (state, data) {
    const { code = '', abbr, index } = data
    state.areaCode.code = code.replace('+', '')
    state.areaCode.abbr = abbr 
    state.areaCode.index = index 
  },
  setCountryCodes (state, data) {
    state.countryCodes = data
  },
  updateDialogName(state, val) {
    state.dialogName = val
  },
  setUseBffApi(state, payload) {
    state.useBffApi = payload
  },
  setUseBffApiV2(state, payload) {
    state.useBffApiV2 = payload
  }
}

export default mutations
