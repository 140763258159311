<template>
  <!-- 会员模块 -->
  <div class="vip-modules__wrapper">
    <!-- 付费会员 -->
    <prime-pannel
      v-if="isPrime"
      :is-show-saver="isShowSaver"
      :language="language"
      :prime-info="primeInfo"
      :payback-info="data.payback_info || {}"
      :renew-pop-up-info="data.renew_pop_up_info || {}"
      :free-gift-popup-info="data.freeGiftPopupInfo || {}"
      :apollo-config="apolloConfig"
    />
    <!-- 超省卡 -->
    <extra-pannel
      v-if="isShowSaver"
      :is-show-prime="isPrime"
      :language="language"
      :save-info="saveInfo"
    />
  </div>
</template>
<script setup>
import { computed } from 'vue'
import PrimePannel from './PrimePannel.vue'
import ExtraPannel from './ExtraPannel.vue'

const props = defineProps({
  apolloConfig: {
    type: Object,
    default: () => ({})
  },
  language: {
    type: Object,
    default: () => ({})
  },
  isShowSaver: {
    type: Boolean,
    default: false
  },
  isPrime: {
    type: Boolean,
    default: false
  },
  data: {
    type: Object,
    default: () => ({})
  }
})
// 付费会员数据
const primeInfo = computed(
  () => props.data.member_card_list?.find(item => item.cardType === 1) || {}
)
// 超省卡数据
const saveInfo = computed(
  () => props.data.member_card_list?.find(item => item.cardType === 2) || {}
)
</script>

<style lang="less" scoped>
.vip-modules__wrapper {
  display: flex;
  margin-top: 8px;
}
</style>
