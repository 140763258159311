import { inject, computed } from 'vue'

export const useCommonState = () => {
  const commonState = inject('commonState', {
    userInfo: {},
    userInfoReady: false,
    language: {},
    abtInfo: {},
    constantData: {},
    isRisk: false
  })

  const userInfo = computed(() => commonState.userInfo)
  const language = computed(() => commonState.language)
  const abtInfo = computed(() => commonState.abtInfo)
  const constantData = computed(() => commonState.constantData)
  const isRisk = computed(() => commonState.isRisk)
  const userInfoReady = computed(() => commonState.userInfoReady)

  return {
    userInfo,
    language,
    abtInfo,
    constantData,
    isRisk,
    userInfoReady
  }
}
