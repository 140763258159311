<template>
  <!-- 订单列表 -->
  <div class="order-list__wrapper">
    <div
      v-if="!orderList"
      class="order-list__loading la-ball-pulse la-ball-pulse-2x la-ball-pulse-black"
    >
      <div></div>
      <div></div>
      <div></div>
    </div>
    <ul
      v-else-if="orderList.length"
      class="order-list__content"
    >
      <order-item
        v-for="(item, index) in orderList.slice(0, total)"
        :key="index"
        :language="language"
        :order="item"
        :index="index"
        :show-preferential-floor="showPreferentialFloor"
      />
    </ul>
    <div
      v-else-if="!isRisk"
      class="order-list__oops"
    >
      <sui_icon_empty_75px_1
        size="75px"
      />
      <p class="order-list__oops-desc">
        {{ language.SHEIN_KEY_PC_15962 }}
      </p>
    </div>
  </div>
</template>
<script setup>
import { computed, onMounted, ref } from 'vue'
import { sui_icon_empty_75px_1 } from '@shein-aidc/icon-vue3'
import OrderItem from './OrderItem.vue'
import { useCommonState } from '../../utils/useCommonState'

const props = defineProps({
  orderList: {
    type: Array,
    default: null
  },
  showPreferentialFloor: {
    type: Boolean,
    default: false
  }
})
const { language, isRisk } = useCommonState()

const isMounted = ref(false)
onMounted(() => {
  isMounted.value = true
})

// @perf ssr只编译首屏3个
const total = computed(() => isMounted.value ? props.orderList?.length || 0 : 3)

</script>
<style lang="less">
.order-list {
  &__loading {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 304px;
  }
  &__content {
    margin: 0 10px;
    padding: 20px;
    background: #fafbfc;
  }
  &__oops {
    text-align: center;
    padding: 40px;
    background: #fafbfc;
  }

  &__oops-desc {
    margin-top: 10px;
    color: #999;
    font-size: 12px;
  }
}
</style>
