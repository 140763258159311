<template>
  <div
    v-expose="{ id: '1-22-2-31' }"
    class="news-pannel__wrapper"
  >
    <div class="news-pannel__header">
      <div class="news-pannel__title">
        <sui_icon_me_news_20px
          size="20px"
          color="#337EFF"
        />
        <div class="news-pannel__title-text">
          {{ language.SHEIN_KEY_PC_30636 }}
        </div>
      </div>
      <div
        v-tap="{ id: '1-22-2-32', data: { button_type: 'more' } }"
        class="news-pannel__more"
        role="link"
        tabindex="0"
        @click="handleMoreClick"
      >
        {{ language.SHEIN_KEY_PC_15663 }}
        <sui_icon_more_right_12px
          size="12px"
          class="news-pannel__more-icon"
          color="#666"
          :is-rotate="constantData.GB_cssRight"
        />
      </div>
    </div>
    <div class="news-pannel__content">
      <swiper-container
        ref="swiperRef"
        class="swiper-container"
        init="false"
        destroy-on-disconnected="false"
      >
        <swiper-slide
          v-for="item in data"
          :key="item.title"
          v-tap="{ id: '1-22-2-32', data: getAnaData(item) }"
          class="swiper-container__item"
          @click="handleItemClick(item)"
        >
          <div
            class="swiper-container__item-text"
            v-html="item.title"
          ></div>
        </swiper-slide>
      </swiper-container>
    </div>
  </div>
</template>

<script setup>
import { register } from 'swiper/element'
import { Autoplay } from 'swiper/modules'
import { ref, onMounted } from 'vue'
import { sui_icon_me_news_20px, sui_icon_more_right_12px } from '@shein-aidc/icon-vue3'
import { CccLink } from 'public/src/pages/components/ccc/common/ccc-link.js'
import { useCommonState } from '../../utils/useCommonState'

typeof window !== 'undefined' && register()

defineProps({
  data: {
    type: Array,
    default: () => []
  }
})
const { language, constantData } = useCommonState()

const getAnaData = (item = {}) => {
  let content_list = '-'
  if (item.hrefType && item.hrefTarget) {
    content_list = `${item.hrefType}_${item.hrefTarget}`
  }
  return {
    button_type: 'content',
    content_list
  }
}

const swiperRef = ref(null)
const cccLink = ref()

const initSwiper = () => {
  const options = {
    direction: 'vertical',
    loop: true,
    initialSlide: 0,
    modules: [Autoplay],
    autoplay: {
      delay: 3000,
      disableOnInteraction: false
    },
    unAutoInit: true
  }
  Object.assign(swiperRef.value, options)
  swiperRef.value.initialize()
}

const initCCCLink = () => {
  cccLink.value = new CccLink({
    sceneData: {
      pageFrom: 'me'
    }
  })
}

onMounted(() => {
  initSwiper()
  initCCCLink()
})

const handleMoreClick = () => {
  window.open(`${constantData.value.langPath}/user/news?page_from=me`, '_blank')
}
const handleItemClick = (item = {}) => {
  window.open(cccLink.value.getFullLink({ item }), '_blank')
}
</script>

<style lang="less" scoped>
.news-pannel {
  &__wrapper {
    width: 286px;
    box-sizing: border-box;
    padding: 20px;
    border-radius: 3px;
    background: rgba(217, 234, 255, 0.5);
    margin-bottom: 10px;

    .swiper-container {
      height: 16px;
      &__item-text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__title {
    display: flex;
    align-items: center;
  }
  &__title-text {
    color: #337eff;
    font-size: 16px;
    font-weight: 700;
    margin-left: 4px;
  }
  &__more {
    color: #666;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
  }
  &__more-icon {
    margin-top: -2px;
  }
  &__content {
    margin-top: 8px;
    color: #222;
    font-size: 14px;
    cursor: pointer;
    height: 16px;
    overflow: hidden;
  }
}
</style>
