
import { reactive, nextTick, ref } from 'vue'

// 服务端数据：controllers/user/user_index/pageData.js
export const useCommonState = (props) => {
  const isSsr = !props.context?.noSsr && Object.keys(props.context).length

  const {
    SiteUID,
    dateLangMap,
    lang,
    PUBLIC_CDN = '',
    IS_RW = '',
    GB_cssRight = false,
    IMG_LINK = {},
    langPath,
    RESOURCE_SDK
  } = isSsr ? props.context : gbCommonInfo

  const commonState = reactive({
    abtInfo: props.context.abtInfo,
    language: props.context.language,
    userInfo: {},
    userInfoReady: false,
    constantData: {
      SiteUID,
      dateLangMap,
      lang,
      PUBLIC_CDN,
      IS_RW,
      GB_cssRight,
      IMG_LINK,
      langPath,
      RESOURCE_SDK
    },
    apolloConfig: props.context.apolloConfig,
    isRisk: true
  })

  const setCommonState = ({ firstPartInfo, userInfo }) => {
    commonState.userInfo = Object.assign(
      {},
      userInfo,
      firstPartInfo.userLevelInfo,
      firstPartInfo.personalInfo,
    )
    nextTick(() => {
      commonState.userInfoReady = true
    })
    commonState.isRisk =
      commonState.userInfo.is_black_list_user === 1 ||
      commonState.userInfo.is_new_reveal === 1
  }
  const firstPartInfo = props.context.firstPartInfo?.info || {}
  setCommonState({
    firstPartInfo,
    userInfo: props.context.userInfo,
  })

  return {
    commonState,
  }
}

export const useRecentViews = () => {
  const recentViewList = ref([])
  const initRecentViewList = () => {
    const cacheData = localStorage.getItem('pc_recent_views')
    try {
      const data = JSON.parse(cacheData) || []
      recentViewList.value = data.map(n => ({
        goodsImage: n.goods_img,
        originData: n
      }))
    } catch {
      recentViewList.value = []
    }
  }
  if (typeof window !== 'undefined') {
    initRecentViewList()
  }
  return {
    recentViewList,
  }
}
