const orderBenefitStrMap = {
  1: 'promotion_end', // 活动已经结束
  2: 'coupon_expire', // 券过期
  3: 'promotion_ending', // 活动即将结束
  4: 'return_coupon', // 返券
  5: 'return_points', // 返积分
  6: 'price_protection' // 价保
}

const goodsBenefitStrMap = {
  1: 'lowest', // 历史新低
  2: 'best_seller', // 榜单
  3: 'x_sold' // x件已售
}

export const getScene = (orderItem, abtInfo, inFloor) => {
  if (inFloor) return 'new_floor'
  const popType = abtInfo?.MeOrderUnpaid?.p?.PopType
  if (!orderItem || !popType) return
  const { savedMoneyTip } = orderItem
  const discountType = savedMoneyTip ? '1' : '2'
  return (popType + discountType).toLowerCase()
}

export const getBenefitType = (orderItem, abtInfo) => {
  if (!orderItem) return
  const { newGoodInfo, orderIncentivePoint } = orderItem
  if (!orderIncentivePoint) return
  const orderBenefitStr = orderBenefitStrMap[orderIncentivePoint.type] || '-'
  const popType = abtInfo?.MeOrderUnpaid?.p?.PopType
  return newGoodInfo
    ?.map((n, i) =>
      [
        i + 1, // 报告坑位
        orderBenefitStr, // 订单利益点
        popType === 'A'
          ? ''
          : goodsBenefitStrMap[n.type] || '-', // 商品利益点
        n.stockTip ? 'sold_out' : 'in_stock' // 库存情况
      ]
        .filter(Boolean)
        .join('`')
    )
    .join(',')
}
