import {
  EXPIRE_KEY_MAP,
  ExpireTipsClickStatus
} from 'public/src/pages/user_index/constants'
import { getLocalStorage } from 'public/src/pages/user_index/utils/storage.js'
import { isLogin } from 'public/src/pages/common/utils'

export const getUserAssetsBottomContent = ({
  userInfo,
  expirePointCouponInfo,
  isHideModuleAction = false
}) => {
  const controls = {
    showExpireGameMoney: false,
    showExpireCouponTips: false,
    showExpirePointTips: false
  }
  if (isHideModuleAction) {
    // 如果是显式隐藏模块，则隐藏后续模块
    return controls
  }
  initExpireGameMoneyTipsStatus({
    controls,
    userInfo,
    expireGameMoney: expirePointCouponInfo.walletTip
  })
  if (controls.showExpireGameMoney) {
    return controls
  }
  initExpireCouponTipsStatus({
    controls,
    userInfo,
    expireCouponInfo: expirePointCouponInfo.expireCouponTotal
  })
  if (controls.showExpireCouponTips) {
    return controls
  }
  initExpirePointsTipsStatus({
    controls,
    userInfo,
    expirePointInfo: expirePointCouponInfo.expirePointTotal
  })
  return controls
}

export const getExpireCacheKey = (type, userInfo = {}) => {
  const userId = isLogin() ? userInfo.member_id : 'anonymous'
  return `${EXPIRE_KEY_MAP[type]}_${userId}`
}

export const getExpireGameMoneyCache = userInfo => {
  const cacheKey = getExpireCacheKey('gameMoney', userInfo)
  return getLocalStorage(cacheKey, {
    status: ExpireTipsClickStatus.CAN_BE_CLOSED,
    closeTimes: 0
  })
}

const initExpireGameMoneyTipsStatus = ({
  controls,
  userInfo,
  expireGameMoney
}) => {
  if (!expireGameMoney?.promptTip || !expireGameMoney?.clickFrequency) return
  const expireTipCache = getExpireGameMoneyCache(userInfo)
  if (expireTipCache.status === ExpireTipsClickStatus.NO_LONGER_SHOW) return
  const { clickFrequency } = expireGameMoney
  const reachMaxCloseTimes = expireTipCache.closeTimes >= +clickFrequency
  if (reachMaxCloseTimes) return
  const now = Date.now()
  controls.showExpireGameMoney =
    expireTipCache.closeTimes === 0 || now > expireTipCache.displayAfterTime
}

const initExpireCouponTipsStatus = ({ controls, userInfo, expireCouponInfo } = {}) => {
  const isCouponClicked = getLocalStorage(getExpireCacheKey('coupon', userInfo))
  controls.showExpireCouponTips = !isCouponClicked && expireCouponInfo
}

const initExpirePointsTipsStatus = ({ controls, userInfo, expirePointInfo } = {}) => {
  const isPointClicked = getLocalStorage(getExpireCacheKey('points', userInfo))
  controls.showExpirePointTips = !isPointClicked && expirePointInfo
}
