import { createSSRApp } from 'vue'

import { registerBaseContext } from '../common/common_inject'
import store from '../common/messageStore/index'
import UserCenterContainer from './Container.vue'
import { Icon } from '@shein-aidc/icon-vue3'

export function _createApp(context) {

  const app = createSSRApp(UserCenterContainer, {
    context,
  })
  registerBaseContext(app)
  app.use(store)
  app.component('SvgIcon', Icon)

  return { app, store }
}
