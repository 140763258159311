<template>
  <div
    class="shein-club_badge"
    :style="{
      backgroundImage: `url(${
        IMG_LINK[noBorder ? 'prime-logo_bg' : GB_cssRight ? 'personal-logo_ar' : 'personal-logo']
      })`,
      height: height,
      width: width,
    }"
  >
    <img
      class="club_badge-logo"
      :class="{ 'no-border': noBorder }"
      :src="IMG_LINK['prime-logo']"
      v-if="noBorder"
    />
  </div>
</template>

<script>
const { IMG_LINK, GB_cssRight } = gbCommonInfo
export default {
  name: 'ClubBadge',
  props: {
    noBorder: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: '18px',
    },
    width: {
      type: String,
      default: '86px',
    },
    config: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      IMG_LINK: this.config?.IMG_LINK || IMG_LINK,
      GB_cssRight: this.config?.GB_cssRight || GB_cssRight,
    }
  },
}
</script>

<style lang="less">
.shein-club_badge {
  background-size: 100%;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  .club_badge-logo {
    width: 80%;
    .margin-l(-10%);
  }
}
</style>
