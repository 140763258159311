import { isSwitchBffApiVersion } from '@/public/src/pages/common/bff-new/index.js'

const actions = {
  getUseBffApi({ commit }) {
    const value = isSwitchBffApiVersion(['userMessage_v1', 'userMessage_v2'])
    commit('setUseBffApi', value.userMessage_v1)
    commit('setUseBffApiV2', value.userMessage_v2)
  }
}

export default actions
