<template>
  <div>
    <!-- 游戏赠送钱包金额临过期提示 -->
    <ExpireTips
      v-if="moduleControls.showExpireGameMoney"
      :arrow-position="3"
      :data="expirePointCouponInfo.walletTip?.promptTip"
      @on-click="handleGameMoneyClick"
      @on-close="handleGameMoneyClose"
    />
    <!-- 临过期优惠券 -->
    <ExpireTips
      v-else-if="moduleControls.showExpireCouponTips"
      class="coupon-expire-tips"
      :arrow-position="1"
      :data="expirePointCouponInfo.expireCouponTotal"
      @on-click="handleCouponOrPointsClick('coupon')"
      @on-close="handleCouponOrPointsClose('coupon')"
    />
    <!-- 临过期积分 -->
    <ExpireTips
      v-else-if="moduleControls.showExpirePointTips"
      class="points-expire-tips"
      :arrow-position="2"
      :data="expirePointCouponInfo.expirePointTotal"
      @on-click="handleCouponOrPointsClick('points')"
      @on-close="handleCouponOrPointsClose('points')"
    />
  </div>
</template>

<script setup name="UserAssetsBottom">
import { onMounted, ref, watch } from 'vue'
import { setLocalStorage } from '@shein/common-function'
import ExpireTips from './ExpireTips.vue'
import {
  getUserAssetsBottomContent,
  getExpireCacheKey,
  getExpireGameMoneyCache
} from './utils'
import { ExpireTipsClickStatus } from '../../../constants'
import { useCommonState } from '../../../utils/useCommonState'

const props = defineProps({
  expirePointCouponInfo: {
    type: Object,
    default: null
  }
})

const { userInfo } = useCommonState()
const emits = defineEmits(['on-close', 'on-click'])

const handleGameMoneyClick = () => {
  setTimeout(() => {
    window.location.href = '/user/wallet'
  }, 300)
  updateModule({ isHideModuleAction: true })
  const cacheKey = getExpireCacheKey('gameMoney', userInfo.value)
  setLocalStorage({
    key: cacheKey,
    value: { status: ExpireTipsClickStatus.NO_LONGER_SHOW }
  })
  daEventCenter.triggerNotice({
    daId: '1-22-2-36'
  })
}

const handleGameMoneyClose = () => {
  updateModule({ isHideModuleAction: true })
  const expireTipCache = getExpireGameMoneyCache(userInfo.value)
  const after24Hours = new Date().getTime() + 24 * 3600 * 1000
  const cacheKey = getExpireCacheKey('gameMoney', userInfo.value)
  setLocalStorage({
    key: cacheKey,
    value: {
      ...expireTipCache,
      closeTimes: expireTipCache.closeTimes + 1,
      displayAfterTime: after24Hours
    }
  })
  daEventCenter.triggerNotice({
    daId: '1-22-2-35'
  })
}

const handleCouponOrPointsClose = type => {
  emits('on-close', type)
  updateModule({ isHideModuleAction: true })
}

const handleCouponOrPointsClick = type => {
  emits('on-click', type, `/user/${type}`)
}

const moduleControls = ref({})
const updateModule = ({ isHideModuleAction = false } = {}) => {
  moduleControls.value = getUserAssetsBottomContent({
    userInfo: userInfo.value,
    expirePointCouponInfo: props.expirePointCouponInfo,
    isHideModuleAction
  })
}

onMounted(() => {
  watch(
    () => props.expirePointCouponInfo,
    val => {
      if (!val) return
      updateModule()
    },
    { immediate: true }
  )
})
</script>

<style lang="less">
.coupon-expire-tips,
.points-expire-tips {
  .expire-tips__content {
    padding: 20px 16px 20px 24px;
  }
  .expire-tips__text {
    height: 20px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    line-height: 20px;
  }
  .expire-tips__close-icon {
    width: 14px;
    height: 14px;
  }
  .expire-tips__arrow-wrapper {
    height: 20px;
  }
  .expire-tips__arrow-item {
    border-width: 10px;
    border-color: transparent transparent #fff7e7 transparent;
  }
}
</style>
