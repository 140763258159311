<template>
  <s-dialog
    v-model:visible="currentDialogShow"
    :type="'W480'"
    :show-close="false"
    :close-on-click-modal="false"
    class="payback-dialog"
  >
    <div
      ref="payBackVapRef"
      class="payback-dialog__vap"
      style="display: block"
    ></div>
    <div
      v-expose="getAnalysisData({ id: '1-22-8-42',data:{ bottomtype } })"
      class="payback-dialog__wrapper"
      :class="{
        'payback-dialog__wrapper-animate': visibility === 'visible'
      }"
    >
      <p class="payback-dialog__title">
        {{
          popups_info?.popups_title
        }}
      </p>
      <div
        class="payback-dialog__color"
        :style="{ backgroundImage: `url(${imgs.color})` }"
      ></div>
      <!-- 头部切图 -->
      <div
        class="payback-dialog__content-img"
        :style="{ backgroundImage: `url(${imgs.title})` }"
      >
        <span class="payback-roi__wrapper">
          <span class="payback-roi__top">
            {{ popups_info?.roi_tips }}
          </span>
          <span class="payback-roi__bottom">
            {{ popups_info?.roi_tips }}
          </span>
        </span>
      </div>
      <div class="payback-dialog__content">
        <div
          class="payback-dialog__bg"
          :style="{ backgroundImage: `url(${imgs.bg})` }"
        >
          <div class="payback-dialog__main">
            <!-- 标题 -->
            <p
              class="payback-dialog__content-title"
              v-html="formatText(popups_info?.payback_content, popups_info?.req_currency_price_with_symbol)"
            ></p>
            <!-- 超越用户数量 -->
            <div class="beyond-line">
              <div class="beyond-line__dot"></div>
            </div>
            <div class="payback-dialog__content-beyond">
              <!-- 扫光动画 -->
              <div class="beyond-animation"></div>
              <p v-html="formatText(popups_info?.payback_detail, popups_info?.roi_tips)"></p>
            </div>
            <div class="beyond-line">
              <div class="beyond-line__dot"></div>
            </div>

            <!-- 按钮 -->
            <div
              class="payback-dialog__content-btn"
              :style="{ backgroundImage: `url(${imgs.btn})` }"
              @click="closeHandle"
            >
              <div
                v-if="popups_info?.button_bubble"
                class="payback-dialog__btn-tip"
              >
                <img :src="imgs.angle" />
                {{ popups_info?.button_bubble }}
              </div>
              <span>{{
                popups_info?.button_tips
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <SvgIcon
        class="payback-dialog__close"
        name="sui_icon_close_24px" 
        size="24px"
        @click="closeHandle('close')"
      />
    </div>
  </s-dialog>
</template>
<script>
import { daEventCenter } from 'public/src/services/eventCenter/index'
const { PUBLIC_CDN = '' } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}
export default {
  name: 'PayBackDialog',
  components: {},
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    // firstPartInfo.info.personalCenterEntranceInfo.payback_info
    paybackInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      // need
      isShowPabBackDialog: this.paybackInfo?.popups_info,
      PUBLIC_CDN,
      imgs: {
        angle: `${PUBLIC_CDN}/she_dist/images/prime/renew_discount_bg_tips-e70005d314.png`,
        btn: `${PUBLIC_CDN}/she_dist/images/prime/trial_success_btn-298024ad89.png`,
        bg: `${PUBLIC_CDN}/she_dist/images/prime/pay_back_bg-c12896fe46.png`,
        title: `${PUBLIC_CDN}/she_dist/images/prime/pay_back_title-dd2b87c228.png`,
        color: `${PUBLIC_CDN}/she_dist/images/prime/pay_back_color-ef6294f094.png`
      },
      visibility: 'hidden',
    }
  },
  computed: {
    currentDialogShow(){
      return this.isShowPabBackDialog && this.isShow
    },
    popups_info() {
      return this.paybackInfo?.popups_info || {}
    },
    bottomtype(){
      const buries = this.paybackInfo?.buries || []
      return buries[0]?.defaultParams?.bottomtype
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    formatText(text, value){
      // 将传入的 text 的值，的 %s 替换为 value
      return text.replace(/%s/g, value)
    },
    getAnalysisData({ id, data }) {
      return {
        id,
        data
      }
    },
    init(){
      this.animationBengin()
    },
    // 动画开始
    animationBengin() {
      setTimeout(() => {
        this.visibility = 'visible'
        setTimeout(() => {
          var elements = document.querySelectorAll('.beyond-animation')
          elements.forEach(function(element) {
            element.style.left = '120%'
          })
        }, 1000)
      }, 166)
    },
    // 关闭弹窗
    closeHandle(type) {
      if(type !== 'close') {
        daEventCenter.triggerNotice({
          daId: '1-22-8-43',
          bindData: { bottomtype: this.bottomtype }
        })
      }
      this.isShowPabBackDialog = false
      if (type == 'close') return
    }
  }
}
</script>

<style lang="less">
@keyframes pay-back-scale {
  0% {
    transform: scale(0.7);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.payback-dialog {
  background: rgba(0, 0, 0, 0.9);
  .sui-dialog__wrapper {
    background: rgba(0, 0, 0, 0);
  }
  &__vap {
    position: relative;
    & canvas {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(-8%, 0%);
    }
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
    opacity: 0;
    position: relative;
    z-index: 2;
  }
  &__wrapper-animate {
    opacity: 1;
    animation: pay-back-scale 0.5s ease-in-out;
  }
  &__title {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    color: #ffae97;
    text-shadow: 0px 0px 15px #ff9159;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 8px;
  }
  &__color {
    width: 300px;
    height: 74px;
    background-repeat: no-repeat;
    background-size: 100%;
    margin-bottom: -58px;
  }
  &__content {
    background: linear-gradient(180deg, #ffba97 0%, #ffe2d3 37.53%, #ffe2d3 100%);
    border-radius: 8px;
    margin-bottom: 33px;
    border: 1px solid rgba(253, 143, 88, 0.6);
  }
  &__bg {
    padding: 8px;
    background-repeat: no-repeat;
    background-size: 100%;
  }
  &__content-img {
    position: relative;
    z-index: 2;
    margin-bottom: -72px;
    width: 300px;
    height: 130px;
    background-repeat: no-repeat;
    background-size: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    .payback-roi__wrapper {
      width: 50px;
      height: 30px;
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
      .payback-roi__top {
        position: absolute;
        z-index: 2;
        color: #ffe9de;
        left: 50%;
        transform: translate3d(-50%, 0, 2px);
        // background: linear-gradient(110.64deg, #FFECE3 19.28%, #FFD2BB 84.4%);
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
        // background-clip: text;
        // text-fill-color: transparent;
      }
      .payback-roi__bottom {
        position: absolute;
        z-index: 1;
        color: #cc5d25;
        left: calc(50% + 1px);
        top: 2px;
        transform: translate3d(-50%, 0, 1px);
      }
    }
  }
  &__main {
    border-radius: 5px;
    border: 1px solid #fd8f58;
    padding: 16px 16px 10px;
  }
  &__content-title {
    margin-top: 46px;
    font-size: 17px;
    font-weight: 700;
    color: #873c00;
    line-height: 26px;
    margin-bottom: 16px;
    span {
      font-size: 26px;
      color: @sui_color_discount;
    }
  }
  &__content-subtitle {
    font-size: 12px;
    line-height: 14px;
    color: rgb(135, 60, 0, 0.8);
    margin-bottom: 24px;
    text-align: left;
    span {
      font-weight: 700;
      color: @sui_color_discount;
    }
  }
  .beyond-line {
    position: relative;
    width: 100%;
    height: 1px;
    background: linear-gradient(
      270deg,
      rgba(135, 60, 0, 0) 0%,
      rgba(234, 128, 74, 0.2) 19.65%,
      rgba(234, 130, 77, 0.2) 80.59%,
      rgba(135, 60, 0, 0) 100%
    );
    .beyond-line__dot {
      position: absolute;
      left: 50%;
      top: -2px;
      z-index: 2;
      margin-left: -2px;
      width: 4px;
      height: 4px;
      background: linear-gradient(135deg, #ffcfb6 0%, #ffb995 91.67%);
      transform: rotate(45deg);
    }
  }
  &__content-beyond {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 53px;
    color: rgb(135, 60, 0, 0.8);
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(
      90deg,
      rgba(255, 238, 230, 0) 0%,
      rgba(255, 238, 230, 0.9) 52.12%,
      rgba(255, 238, 230, 0) 100%
    );
    .beyond-animation {
      position: absolute;
      left: -37px;
      top: -8px;
      width: 30px;
      height: 64px;
      opacity: 0.9;
      transform: rotateZ(20deg);
      background: linear-gradient(
        95deg,
        rgba(255, 255, 255, 0.1),
        rgba(255, 255, 255, 0.5),
        rgba(255, 255, 255, 0.1)
      );
      transition: left 0.75s ease-in-out;
    }
    span {
      font-size: 24px;
      line-height: 29px;
      font-weight: 700;
      margin: 0 6px;
      color: @sui_color_discount;
      text-transform: lowercase;
    }
  }
  &__content-btn {
    width: 260px;
    height: 59px;
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    color: #fff;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 16px auto 0;
    position: relative;
  }
  &__btn-tip {
    border-radius: 40px;
    background: linear-gradient(96deg, #ff405e 0%, #c73bff 54.26%);
    position: absolute;
    right: 55px;
    top: -16px;
    height: 20px;
    color: #fff;
    text-align: center;
    font-size: 10px;
    font-weight: 590;
    display: flex;
    align-items: center;
    padding: 0 10px;
    img {
      width: 6px;
      height: 4px;
      position: absolute;
      left: 50%;
      bottom: -4px;
      transform: translateX(-50%);
    }
  }
  &__close {
    cursor: pointer;
  }
}
</style>
